<template>
  <div>
    <div v-if="!progress">
      <div class="pb-4">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex justify-start">
                <div class="type_img mr-2">
                  <v-img :src="labels[ticket.type].img"></v-img>
                </div>
                <div v-html="ticket_title(ticket)"></div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                class="d-flex justify-space-between"
                v-html="ticket_content(ticket)"
              ></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div>
        <v-list-item-group v-model="model">
          <v-list-subheader v-if="top_list.length != 0"
            >BEST MATCH</v-list-subheader
          >
          <v-list-item
            v-for="(item, index) in top_list"
            :key="index"
            class="top_item ma-2"
          >
            <v-list-item-content @click="selectItem(item)">
              <v-list-item-title>
                <div class="d-flex justify-space-between my-4">
                  <div v-if="item.order.gift_name != undefined">
                    {{ item.order.gift_name }}
                  </div>
                  <div v-if="item.order.group_name != undefined">
                    {{ item.order.group_name }}
                  </div>
                  <div v-else>
                    {{
                      item.order.client_firstname +
                      " " +
                      item.order.client_lastname
                    }}
                  </div>
                  <div>
                    Ticket scanned: {{ products_ready(item.order) }}/{{
                      item.order.products.length
                    }}
                  </div>
                </div>
              </v-list-item-title>
              <v-divider> </v-divider>
            </v-list-item-content>
          </v-list-item>
          <v-list-subheader v-if="top_list.length != 0"
            >NORMAL MATCH</v-list-subheader
          >
          <v-list-item
            v-for="(item, key2) in normal_list"
            :key="key2"
            class="normal_item ma-2"
          >
            <v-list-item-content @click="selectItem(item)">
              <v-list-item-title>
                <div class="d-flex justify-space-between my-4">
                  <div>
                    {{
                      item.order.client_firstname +
                      " " +
                      item.order.client_lastname
                    }}
                  </div>
                  <div>
                    Ticket scanned:{{ products_ready(item.order) }}/{{
                      item.order.products.length
                    }}
                  </div>
                </div>
              </v-list-item-title>
              <v-divider> </v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </div>
      <emb-delete-confirmation
        ref="selectConfirmationDialog"
        :message="selectOrderMessage"
        @onConfirm="onSelectCustomer"
      >
      </emb-delete-confirmation>
    </div>
    <div v-else class="d-flex justify-center align-center">
      <v-progress-circular
        indeterminate
        color="primary"
        size="128"
        absolute
        top
        bottom
        left
        right
      ></v-progress-circular>
    </div>
  </div>
</template>
<style scoped>
.top_item {
  background-color: rgb(255, 255, 182);
  font-weight: bold;
  border: 2px solid red;
}
.normal_item {
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
}
.type_img {
  width: 75px;
}
</style>
<script>
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as moment from "moment";

export default {
  data() {
    return {
      progress: false,
      model: null,
      selected_item: null,
      labels: {
        powerball: {
          multiplier: "Powerplay",
          type: "Powerball",
          img: "/static/images/powerball_mini.png",
        },
        megamillions: {
          multiplier: "Megaplier",
          type: "Mega Millions",
          img: "/static/images/megamillions_mini.png",
        },
      },
      top_list: [],
      normal_list: [],
    };
  },
  props: ["ticket", "user_orders_list", "image_info"],
  computed: {
    selectOrderMessage() {
      return (
        "Select user " +
        this.selected_item?.order.client_firstname +
        " " +
        this.selected_item?.order.client_lastname +
        "?"
      );
    },
  },
  mounted() {
    console.log("this.ticket", this.ticket);
    console.log("this.user_orders_list", this.user_orders_list);

    // Convert top element to top list
    var tmp_top_list = [];
    for (var top_order in this.user_orders_list.top) {
      tmp_top_list.push(this.user_orders_list.top[top_order]);
    }
    var tmp_normal_list = [];
    for (var normal_order in this.user_orders_list.normal) {
      tmp_normal_list.push(this.user_orders_list.normal[normal_order]);
    }

    tmp_top_list.sort((a, b) =>
      this.compare_name(a).localeCompare(this.compare_name(b))
    );
    this.top_list = tmp_top_list;
    console.log("this.top_list", this.top_list);

    tmp_normal_list.sort((a, b) =>
      this.compare_name(a).localeCompare(this.compare_name(b))
    );
    this.normal_list = tmp_normal_list;
  },
  methods: {
    compare_name(obj) {
      if (obj.gift_name != undefined) {
        return obj.gift_name;
      } else if (obj.group_name != undefined) {
        return obj.group_name;
      } else {
        return obj.client_firstname;
      }
    },
    selectItem(item) {
      console.log("selectItem:", item);
      this.selected_item = item;
      this.$refs.selectConfirmationDialog.openDialog();
    },
    // Convert grids from format 01-02-03-04-05:06 to 01-02-03-04-05 (06)
    convertGrids(grids) {
      var new_grids = [];
      for (var grid of grids) {
        var new_grid = grid.replace(":", " (") + ")";
        new_grids.push({
          error: "",
          play: new_grid,
        });
      }
      return new_grids;
    },
    onSelectCustomer() {
      this.progress = true;
      this.$refs.selectConfirmationDialog.close();

      var draw_dates = []
      this.ticket.draw_dates.forEach((date_element) => {
        const date = new Date(date_element);
        const options = { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit' };
        draw_dates.oush(date.toLocaleDateString('en-US', options));
      });

      // Push the ticket to completed if needed
      var order = this.selected_item.order;
      var scan = {
        first_draw_date: moment(this.ticket.first_draw_date).toDate(),
        last_draw_date: moment(this.ticket.last_draw_date).toDate(),
        grids: this.convertGrids(this.ticket.grids),
        multiplier: this.ticket.multiplier,
        image: this.image_info.url,
        path: this.image_info.path,
        draw_dates: draw_dates,
      };
      console.log("Scan: ", scan);
      order.products[this.selected_item.product_index].scan = scan;
      order.products[this.selected_item.product_index].ready = true;

      // Check if all products are ready
      var all_products_ready = true;
      var files_info = {};
      for (var product_index in order.products) {
        const product = order.products[product_index];
        var file_info = {
          product_index: product_index,
          source: product.scan.path,
        };
        files_info[product_index] = file_info;

        if (product.ready == undefined) {
          all_products_ready = false;
          break;
        }
      }
      const move_file_info = {
        order_id: order.id,
        files_info: files_info,
      };

      if (all_products_ready) {
        order.status = "COMPLETED";
        order.general_status = "COMPLETED";
        order.update_date = new Date();

        // Move file if all products are completed
        const moveFileFunc = httpsCallable(getFunctions(), "moveFile");
        moveFileFunc({
          move_file_info,
        })
          .then((response) => {
            console.log("order", order);
            console.log("Response: ", response);
            for (var file_index in response.data.data) {
              var file_destination = response.data.data[file_index];
              order.products[file_index].scan.path = file_destination;
              order.products[file_index].scan.image = "";
            }
            // Update the order
            setDoc(doc(getFirestore(), "orders", order.id), order).then(() => {
              this.$router.replace("/admin-panel/scan-batch");
            });
            this.progress;
          })
          .catch((error) => {
            console.log("Error: ", error);
            this.progress = false;
          });
      }
    },
    products_ready(order) {
      let count = 0;
      order.products.forEach((product) => {
        if (product.ready) {
          count++;
        }
      });
      return count;
    },
    ticket_title(ticket) {
      var title =
        " with " +
        (ticket.multiplier == true ? this.labels[ticket.type].multiplier : "");

      if (ticket.draw_dates.length > 1) {
        title += " multiplay x" + ticket.draw_dates.length;
      }
      return title;
    },
    ticket_content(ticket) {
      var content = "";
      for (var grid of ticket.grids) {
        content += grid + "<br>";
      }
      return content;
    },
  },
};
</script>
